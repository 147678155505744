import { findIndex, orderBy, slice, pick, filter, isEmpty, get, capitalize, uniq } from 'lodash-es';
import memoize from 'proxy-memoize';
import { queryResult, doc } from '../dreamdb/selectors';
import * as router from '../router/selectors.js';
import { LIST_PAGE_NAME } from './constants.js';
import moment from 'moment/dist/moment.js';

/**
 * @returns {Array} list of videos.
 */
export const allVideos = (state) => queryResult(state, 'all-videos');

/**
 * @returns {Array} list of videos matching applied `filters()`.
 *  Default: Sorted on `uploadedAt`.
 *  Filter: Sorted alphabetically on `name`.
 */
export const list = memoize((state) => {
  let _allVideos = allVideos(state);
  if (!_allVideos) {
    return [];
  }

  const _filters = filters(state);
  if (!_filters) {
    return orderBy(_allVideos, ['uploadedAt'], ['desc']);
  }

  _allVideos = filter(_allVideos, (video) => {
    const categoryMatched = _filters.category ? capitalize(video.category) === _filters.category : true;
    const adhyayMatched = _filters.adhyay ? video?.tags?.adhyay === _filters.adhyay : true;
    const shlockMatched = _filters.shlock ? video?.tags?.shlock === _filters.shlock : true;
    const pravachanMatched = _filters.pravachan ? video?.tags?.pravachan === _filters.pravachan : true;
    const nameMatched = _filters.name ? video?.tags?.name === _filters.name : true;
    const mantraMatched = _filters.mantra ? video?.tags?.mantra === _filters.mantra : true;
    const uploadedFromMatched = _filters.uploadedFrom ? _filters.uploadedFrom >= dateByUnixTimestamp(video.uploadedAt) : true;
    const uploadedToMatched = _filters.uploadedTo ? _filters.uploadedTo <= dateByUnixTimestamp(video.uploadedAt) : true;
    const recordedFromMatched = _filters.recordedFrom ? _filters.recordedFrom >= video.recordingDate : true;
    const recordedToMatched = _filters.recordedTo ? _filters.recordedTo <= video.recordingDate : true;
    const statusMatched = _filters.status ? video.status === _filters.status : true;
    const languageMatched = _filters.language ? video.language === _filters.language : true;
    const availableForScheduleMatched = _filters.availableForSchedule !== undefined ? video.availableForSchedule === _filters.availableForSchedule : true;
    const typeMatched = _filters.videoType ? (_filters.videoType === 'attachment') === video.attachment : true;

    return categoryMatched && adhyayMatched && shlockMatched && pravachanMatched && nameMatched && mantraMatched && uploadedFromMatched && statusMatched && uploadedToMatched && recordedFromMatched && recordedToMatched && languageMatched && availableForScheduleMatched && typeMatched;
  });

  return orderBy(_allVideos, ['name'], ['asc']);
});

/**
 *
 * @param {Number} unixTimestamp
 * @returns {String} date in `YYYY-MM-DD` format
 */
const dateByUnixTimestamp = (unixTimestamp) => {
  return moment.unix(unixTimestamp / 1000).format("YYYY-MM-DD");
};

/**
 * Current applied filters on videos.
 * @param {Object} state Redux state.
 * @returns {Object} Applied filters. e.g. `{ category: "", adhyay: "", shlock: "", pravachan: "", name: "", mantra: "", uploadedFrom: "", uploadedTo: "", recordedFrom: "", recordedTo: "", status: "", language: "", availableForSchedule: "", type: "" }`.
 *  `undefined` when no filter is applied.
 */
export const filters = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  const filters = pick(pageParams, ['category', 'adhyay', 'shlok', 'pravachan', 'name', 'mantra', 'uploadedFrom', 'uploadedTo', 'recordedFrom', 'recordedTo', 'status', 'language', 'availableForSchedule', 'videoType']);
  return isEmpty(filters) ? undefined : filters;
};

/**
 * @returns {Array} list of all active videos, non-attachment.
 */
export const allActive = memoize((state) => {
  let videos = queryResult(state, 'all-videos') || [];
  videos = videos.filter((video) => !video.attachment);
  videos = orderBy(videos, [(v) => v.category, (v) => {
    switch (v.category) {
      case 'Geeta':
        return v?.tags?.adhyay;
      case 'Ved':
      case 'Upanishad':
        return v?.tags?.name;
      default: //Utsav, Event & Other
        return v.name;
    }
  }, (v) => {
    switch (v.category) {
      case 'Geeta':
        return v?.tags?.shlok;
      case 'Ved':
      case 'Upanishad':
        return v?.tags?.adhyay;
      default: //Utsav, Event & Other
        return;
    }
  }, (v) => {
    switch (v.category) {
      case 'Geeta':
        return v?.tags?.pravachan;
      case 'Ved':
      case 'Upanishad':
        return v?.tags?.mantra;
      default: //Utsav, Event & Other
        return;
    }
  }, (v) => {
    switch (v.category) {
      case 'Ved':
      case 'Upanishad':
        return v?.tags?.pravachan;
      case 'Geeta':
      default: //Utsav, Event & Other
        return;
    }
  }]);
  return videos;
});

const _filterAvailableForSchedule = (videos) => {
  return filter(videos, (v) => v.status === 'READY' && v.availableForSchedule);
}

export const allActiveAvailableForSchedule = memoize((state) => {
  const videos = allActive(state);
  return _filterAvailableForSchedule(videos);
});


/**
 * @returns {Array} list of all active attachment videos.
 */
export const allActiveAttachments = memoize((state) => {
  const videos = queryResult(state, 'all-videos') || [];
  return videos.filter((video) => video.attachment);
});

export const allActiveAttachmentsAvailableForSchedule = memoize((state) => {
  const videos = allActiveAttachments(state);
  return _filterAvailableForSchedule(videos);
});

export const video = (state, videoId) => doc(state, videoId);


/**
 * @returns {Array} list of matching Videos, between start & end video, both inclusive.
 */
export const findBetween = (state, startVideoId, endVideoId) => {
  const videos = allActive(state);
  const startIndex = findIndex(videos, ['_id', startVideoId]);
  const endIndex = findIndex(videos, ['_id', endVideoId], startIndex);
  if (endIndex == -1) {
    return [];
  }

  const selectedVideos = slice(videos, startIndex, endIndex + 1);

  //apply language filter
  const language = selectedVideos[0].language;
  if (language !== selectedVideos[selectedVideos.length - 1].language) {
    throw new Error('start and end video should be of same language');
  }
  return selectedVideos.filter(video => (video.language === language));
}

/**
 * @returns {Array} N number of videos, starting from startVideoId, inclusive.
 */
export const findFrom = (state, startVideoId, n) => {
  const videos = allActive(state);
  const startIndex = findIndex(videos, ['_id', startVideoId]);
  if (startIndex == -1) {
    return [];
  }

  const language = videos[startIndex].language;
  return slice(videos, startIndex).filter((video) => video.language === language).slice(0, n);

}

export const selection = (state) => {
  return get(state, `videos.list.selection`);
}

export const languages = (state) => {
  let videos = allVideos(state);

  if (!videos) {
    return [];
  }

  return uniq(videos.map(v => v.language));
}

export const schedulesByVideo = (state, videoId) => {
  const schedules = queryResult(state, `videos/schedules-by-week`);

  if (!schedules) {
    return;
  }

  if (!schedules.length) {
    return null;
  }

  return filter(schedules, (s) => s.video === videoId);
};

/**
 * It returns true if filter dialog is opened. false otherwise.
 * @param {Object} state
 */
export const filterDialogOpened = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return false;
  }

  const pageParams = router.pageParams(state);
  return pageParams?.action === 'filter';
};

export const newVideo = (state) => {
  return state.videos.newVideo;
};

/**
 *
 * @returns progress stats of file uploaded
 */
export const uploadProgress = (state, videoId) => {
  return get(state, `videos.uploadRequestStatus.${videoId}.progress`);
}

/**
 * Subtitle upload/delete/download is in progress.
 * @returns {object} { language: true/false }
 */
export const videoSubtitlesUpdates = (state, videoId) => {
  return get(state, `videos.subtitlesUpdates.${videoId}`);
}

export const subtitlesDownloadJwt = (state, videoId) => {
  return get(state, `videos.subtitlesDownloadJwt.${videoId}`);
}
